<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-select
          allowClear
          @change="sectionChangeSelect"
          v-model="params.section"
          size='small'
          style="width:150px;margin:5px;"
          placeholder='栏目选择'
        >
          <a-select-option value='1'>我的权益</a-select-option>
          <a-select-option value='2'>精选服务</a-select-option>
        </a-select>

        <a-select
          allowClear
          @change="changeSelect"
          v-model="params.type"
          size='small'
          style="width:150px;margin:5px;"
          placeholder='可见范围'
        >
          <a-select-option value=0>所有端</a-select-option>
          <a-select-option value=1>小程序端</a-select-option>
          <a-select-option value=2>移动端</a-select-option>
        </a-select>

        <a-button
          @click="search"
          size='small'
          style="margin-left:10px;"
        >搜索</a-button>
      </div>
      <a-button
        size='small'
        @click="openModal({})"
      >新增数据</a-button>
    </div>
    <div class="table-content">
      <a-table
        @change="changePage"
        :pagination='pagination'
        :loading='loading'
        :columns="columns"
        :data-source="data"
        :rowKey="(record,n)=>n"
      >
        <div
          slot="Image"
          slot-scope="row"
          style="width:100px;height:100px;display:flex;justify-content:center;align-items:center;"
        >
          <img
            style="max-width:100px;max-height:100px;"
            :src="row"
          >
        </div>
        <div
          slot="setup"
          slot-scope="row"
        >
          <a
            class="map-margin"
            @click="openModal(row)"
          >编辑</a>

          <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="remove(row)"
          >
            <a style='margin-left:5px'>删除</a>
          </a-popconfirm>
        </div>
      </a-table>
    </div>

    <a-modal
      centered
      :width='900'
      v-model="showModal"
      @ok='checkForms'
      @cancel='cancel'
    >
      <div style="height:450px;overflow-y:auto;">
        <a-form-model
          v-bind="layout"
          :model='forms'
          :rules='rules'
          ref="ruleForm"
        >
          <a-form-model-item
            label='栏目'
            prop='section'
          >
            <a-select
              @change="sectionChangeSelect"
              v-model="forms.section"
              :filter-option="untils.filterOption"
              placeholder='请选择栏目'
            >
              <a-select-option value='1'>我的权益</a-select-option>
              <a-select-option value='2'>精选服务</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            label='功能范围'
            prop='type'
          >
            <a-select
              @change="changeSelect"
              ref='addse'
              v-model="forms.type"
              :filter-option="untils.filterOption"
              placeholder='请选择'
            >
              <a-select-option value='0'>所有端</a-select-option>
              <a-select-option value='1'>小程序端</a-select-option>
              <a-select-option value='2'>移动端</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            label='是否登录'
            prop='needLogin'
          >
            <a-select
              @change="needLoginChangeSelect"
              ref='addse'
              v-model="forms.needLogin"
              :filter-option="untils.filterOption"
              placeholder='是否登录'
            >
              <a-select-option value='0'>不需要</a-select-option>
              <a-select-option value='1'>需要</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            label='图标'
            prop='icon'
          >
            <a-input
              v-model="forms.icon"
              allowClear
              @paste.stop.prevent="untils.pasteImg($event,2,parsetCallback)"
            >
              <a-upload
                slot='addonBefore'
                name="file"
                :showUploadList='false'
                :action="ip+'/file/upload'"
                @change="handleChange($event,forms)"
              >
                <a>
                  <a-icon type="upload" />
                </a>
              </a-upload>
            </a-input>
          </a-form-model-item>

          <a-form-model-item
            label='功能名称'
            prop='title'
          >
            <a-input
              placeholder='请输入功能名称'
              v-model="forms.title"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item
            label='跳转类型'
            prop='pathType'
          >
            <a-select
              @change="changeType"
              v-model="forms.pathType"
              placeholder='跳转类型'
            >
              <a-select-option value='native'>原生跳转</a-select-option>
              <a-select-option value='h5'>h5页面</a-select-option>
              <a-select-option value='applet'>小程序</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            label='跳转类型值'
            prop='pathValue'
          >
            <a-input
              placeholder='请输入跳转类型值'
              v-model="forms.pathValue"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item label='排序'>
            <a-input
              placeholder='请输入序号'
              v-model="forms.sort"
            ></a-input>
          </a-form-model-item>

        </a-form-model>
      </div>
    </a-modal>

  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import quillConfig from "../../../untils/qull.config";
import { mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      columns: [
        {
          title: "图标",
          dataIndex: "icon",
          scopedSlots: { customRender: "Image" },
        },
        {
          title: "栏目",
          align: "center",
          dataIndex: "section",
          customRender: (text) => {
            const inner = text;
            if (inner == "1") {
              return <span style="color:green">我的权益</span>;
            }
            if (inner == "2") {
              return <span style="color:red">精选服务</span>;
            }
          },
        },
        { title: "功能名称", dataIndex: "title" },
        {
          title: "功能范围",
          align: "center",
          dataIndex: "type",
          customRender: (text) => {
            const inner = text;
            if (inner == "0") {
              return <span style="color:green">所有端</span>;
            }
            if (inner == "1") {
              return <span style="color:red">小程序端</span>;
            }
            if (inner == "2") {
              return <span style="color:red">移动端</span>;
            }
          },
        },
        {
          title: "是否登录",
          align: "center",
          dataIndex: "needLogin",
          customRender: (text) => {
            const inner = text;
            if (inner == "0") {
              return <span style="color:green">否</span>;
            }
            if (inner == "1") {
              return <span style="color:red">是</span>;
            }
          },
        },
        { title: "跳转类型", dataIndex: "pathType" },
        { title: "跳转值", dataIndex: "pathValue" },
        { title: "排序", align: "center", dataIndex: "sort" },
        { title: "创建时间", align: "center", dataIndex: "createTime" },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      params: { pageNum: 1, pageSize: 20 },
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      loading: false,
      IO: 0, // 0 写入 1新增,
      forms: {},
      rules: {
        icon: [{ required: true, message: "图标", trigger: "blur" }],
        title: [{ required: true, message: "标题", trigger: "blur" }],
        section: [{ required: true, message: "栏目", trigger: "blur" }],
        type: [{ required: true, message: "类型", trigger: "change" }],
        pathType: [{ required: true, message: "跳转类型", trigger: "change" }],
        pathValue: [{ required: true, message: "跳转值", trigger: "change" }],
      },
      showModal: false,
      layout: {
        labelCol: { span: 2 }, //标签占2份  共24份
        wrapperCol: { span: 22 },
      },
      layoutAsnswe: {
        labelCol: { span: 2 }, //标签占2份  共24份
        wrapperCol: { span: 22 },
      },
      options: quillConfig,
      visible: false,
    };
  },
  components: {
    quillEditor,
  },

  computed: {
    ...mapState(["ip"]),
  },

  created() {
    this.getData(this.params);
  },
  methods: {
    moment,
    async getData(params) {
      this.loading = true;
      const res = await this.axios("/dq_admin/centerConfigure/list", {
        params: params,
      });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
    },
    search() {
      this.params.pageNum = 1;
      this.getData(this.params);
    },

    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getData(this.params);
    },

    openModal(row) {
      this.showModal = true;
      const { id = null } = row;
      this.IO = id ? 1 : 0;
      if (this.IO == 1) {
        this.forms = JSON.parse(JSON.stringify(row));
        const { type, section, needLogin } = this.forms;
        this.forms.type = type + "";
        this.forms.section = section + "";
        this.forms.needLogin = needLogin + "";
      } else {
        Object.assign(this.forms);
      }
    },

    handleChange(res, forms) {
      const { response } = res.file;
      const { data } = response || { data: null };
      if (data) {
        forms.icon = data;
      }
    },

    async submit() {
      const res = await this.axios.post(
        "/dq_admin/centerConfigure/addOrEdit",
        this.forms
      );
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        this.getData(this.params);
        this.showModal = false;
        this.cancel();
      }
    },

    cancel() {
      this.forms = { icon: "" };
      this.$refs.ruleForm.resetFields();
    },

    checkForms() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },

    parsetCallback(type, result) {
      this.forms.icon = result;
    },

    remove(row) {
      const id = row.id;
      this.axios("/dq_admin/centerConfigure/delById", {
        params: { id: id },
      }).then((res) => {
        this.getData(this.params);

        this.$message.success(res.message);
      });
    },

    changeSelect(e) {
      this.forms.type = e;
    },

    changeType(e) {
      this.forms.pathType = e;
    },

    sectionChangeSelect(e) {
      this.forms.section = e;
    },

    needLoginChangeSelect(e) {
      this.forms.needLogin = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
.take-box {
  width: 100%;
  max-height: 700px;
}
.answer-list {
  padding: 0 10px;
  .answer-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
</style>